import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "components/common/Button"

/* Usage:

const [confirmDialog, setConfirmDialog] = useState(null)
//Open and process the result
setConfirmDialog({
  title: "Action",
  content: "Please confirm",
  handleClose: function (confirmed) {
    //Close dialog
    setConfirmDialog(null)
    if (confirmed) {
      console.log("Do action here")
    }
  },
})
//Render
<ConfirmDialog properties={confirmDialog} />


*/

function ConfirmDialog({ properties, isLoading, children }) {
  //If confirmMessage is falsey the form is closed
  const open = !!properties
  let title = open ? properties.title : null
  let content = open ? properties.content : null
  let handleClose = open ? properties.handleClose : null
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          color="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(true)}
          color="primary"
          isLoading={isLoading}
          autoFocus
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
