import React, { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"
import authService from "services/authService"

function MainNav() {
  const [user, setUser] = useState({})

  useEffect(() => {
    const user = authService.getCurrentUser()
    setUser(user)
  }, [])

  const logout = e => {
    e.preventDefault()
    authService.logout()
  }

  return (
    <StyledNav>
      <Row className="align-items-center">
        {!user && (
          <Col xs="auto">
            <NavLink to="/login">Login</NavLink>
          </Col>
        )}
        {user && (
          <>
            <Col xs="auto">
              <NavLink to="/app-admin">App Management</NavLink>
            </Col>
            <Col xs="auto">
              <NavLink to="/user-management">User Management</NavLink>
            </Col>
            <Col xs="auto">
              <a href="#" onClick={e => logout(e)}>
                Logout
              </a>
            </Col>
          </>
        )}
      </Row>
    </StyledNav>
  )
}

export default MainNav

const StyledNav = styled.nav`
  text-align: center;

  a {
    color: ${vars.white};
    display: inline-block;

    svg {
      font-size: 1.3rem;
      float: left;
      margin: -2.5px 5px 0 0;
    }

    &:hover {
      color: ${vars.secondary};
    }
  }
`
