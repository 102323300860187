import React from "react"
import { Row, Col } from "reactstrap"
import { SettingsCell, PersonOutline } from "@material-ui/icons"
import Card from "components/common/Card"

function HomePage() {
  return (
    <>
      <h1>Admin Dashboard</h1>
      <Row>
        <Col sm="4">
          <Card to="/app-admin" Icon={SettingsCell}>
            App Management
          </Card>
        </Col>
        <Col sm="4">
          <Card to="/user-management" Icon={PersonOutline}>
            User Management
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default HomePage
