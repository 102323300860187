import React from "react"
import styled from "styled-components"

function Image({ src, alt, ...rest }) {
  return <StyledImage src={src} alt={alt} {...rest} />
}

export default Image

const StyledImage = styled.img`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "100%")};
`
