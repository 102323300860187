import React from "react"
import Paper from "components/common/Paper"
import PushNotificationForm from "components/ui/PushNotificationForm"

function CreatePushNotificationPage() {
  return (
    <Paper className="mb-4">
      <PushNotificationForm isCreate />
    </Paper>
  )
}

export default CreatePushNotificationPage
