import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { Col, Row } from "reactstrap"
//Local
import { CodeError, PageError } from "components/common/PageError"
import Paper from "components/common/Paper"
import TextField from "components/common/TextField"
import Button from "components/common/Button"
import useApiError from "hooks/useApiError"
import BackButton from "components/common/BackButton"
import { editUser, saveUser, sendWelcomeEmail } from "services/userService"
import CheckboxWithLabel from "components/common/CheckboxWithLabel"
import DropdownList from "components/common/DropdownList"
import styled from "styled-components"

export default function EditUserPage() {
  //STATE

  const [isLoading, setIsLoading] = useState(true)
  const [pageError, setPageError] = useState(null)
  const [vm, setVm] = useState(null)

  //HOOKS

  const isMounted = useRef(true)
  let { id } = useParams()
  const isCreate = !id || id.toLowerCase() === "new"
  if (isCreate) id = null
  const canEditAccessGroup = false

  const { handleSubmit, control, formState } = useForm()
  const { isDirty, errors } = formState
  const { responseError } = useApiError()
  const history = useHistory()

  useEffect(() => {
    //Get page data on first load
    editUser(id)
      .then(data => {
        if (!isMounted) return
        if (data.responseType === "SUCCESS") {
          //Success
          data.active = data.entityStatusType === 1
          setVm(data)
        } else {
          if (data.responseMessage === "logout")
            window.location.href = "/Account/Login"
          throw new CodeError(data.responseMessage, 1)
        }
        setIsLoading(false)
      })
      .catch(err => {
        setPageError(err.message)
        setIsLoading(false)
      })
    return () => (isMounted.current = false)
  }, [])

  //EVENTS

  async function onSubmit(formData) {
    //Check for missing dropdown as not on react-hook-form
    if (canEditAccessGroup && !vm.accessGroupId) {
      const newVm = { ...vm }
      newVm.accessGroupId_error = "Please select a group"
      setVm(newVm)
      return
    }

    //Save
    formData.entityStatusType = vm.active ? 1 : 2
    setIsLoading(true)
    const response = await saveUser({ ...vm, ...formData })

    if (!isMounted) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response)
    }

    history.goBack()

    //reset(response)
    //setIsLoading(false)
  }

  async function handleSendEmail() {
    setIsLoading(true)
    const response = await sendWelcomeEmail(vm.id)

    if (!isMounted) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response)
    }
    history.goBack()
  }

  function handleError(error) {
    setIsLoading(false)
    responseError(error)
  }

  const handleCheckChange = (event, property) => {
    let newVm = { ...vm }
    newVm[property] = event.target.checked
    setVm(newVm)
  }

  const handleListChange = (event, property) => {
    let newVm = { ...vm }
    newVm[property] = event.target.value
    newVm[property + "_error"] = null
    setVm(newVm)
  }

  //RENDER

  if (isLoading) return "Loading..."

  if (pageError !== null) return <PageError>{pageError}</PageError>

  if (!isMounted) return

  return (
    <Paper>
      <Row className="mb-3 align-items-center" noGutters>
        <Col>
          <h1 className="mb-0">{isCreate ? "Create" : "Edit"} User</h1>
        </Col>
      </Row>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="6" lg="3">
            <TextField
              label="First Name"
              name="firstName"
              defaultValue={vm.firstName}
              control={control}
              error={errors.firstName?.message}
              required
            />
          </Col>
          <Col xs="6" lg="3">
            <TextField
              label="Surname"
              name="surname"
              defaultValue={vm.surname}
              control={control}
              error={errors.surname?.message}
              required
            />
          </Col>

          <Col xs="12" lg="6">
            <TextField
              label="Email"
              name="email"
              defaultValue={vm.email}
              control={control}
              required
              disabled={!isCreate}
              error={errors.email?.message}
            />
          </Col>

          {canEditAccessGroup && (
            <Col xs="12" lg="3" className="pl-4 mb-3">
              <StyledLabel className="mt-3">Access Group</StyledLabel>
              <DropdownList
                id="accessGroupId"
                items={vm.accessGroups}
                value={vm.accessGroupId || ""}
                onChange={e => handleListChange(e, "accessGroupId")}
                codeProperty="value"
                displayProperty="text"
              />
              {vm.categoryId_error && (
                <StyledError>{vm.categoryId_error}</StyledError>
              )}
            </Col>
          )}

          {!isCreate && (
            <Col xs="12" lg="3">
              <div className="pt-3">&nbsp;</div>
              <CheckboxWithLabel
                name="active"
                label="Active"
                control={control}
                defaultChecked={vm.active}
                onChange={e => handleCheckChange(e, "active")}
                className="pt-3"
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col xs="auto" className="mt-3">
            <Button type="submit" isLoading={isLoading}>
              Save
            </Button>
          </Col>

          <Col xs="auto" className="mt-3">
            <BackButton>Back</BackButton>
          </Col>

          {!isCreate && (
            <Col className="mt-3 d-flex justify-content-end">
              <Button
                type="button"
                isLoading={isLoading}
                onClick={handleSendEmail}
              >
                Send Welcome Email
              </Button>
            </Col>
          )}
        </Row>
      </form>
    </Paper>
  )
}

const StyledLabel = styled.div`
  font-size: 0.9rem;
`
const StyledError = styled.div`
  color: red;
`
