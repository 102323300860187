import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { Col, Row } from "reactstrap"
import DateFnsUtils from "@date-io/date-fns" // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
//Wysiwyg
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
// import {
//   RichEditor,
//   htmlToEditor,
//   editorToHtml,
// } from "components/common/RichEditor"
//Local
import { CodeError, PageError } from "components/common/PageError"
import Paper from "components/common/Paper"
import TextField from "components/common/TextField"
import Button from "components/common/Button"
import useApiError from "hooks/useApiError"
import BackButton from "components/common/BackButton"
import { editEvent, saveEvent } from "services/eventsService"
import { uploadImage } from "services/infoHubService"
import ImageList from "components/hee/ImageList"
import DropdownList from "components/common/DropdownList"
import TinyMceEditor from "components/common/TinyMceEditor"
import { dateWithoutTime } from "utils/miscUtils"
import styled from "styled-components"
import CheckboxWithLabel from "components/common/CheckboxWithLabel"

export default function EditEventPage() {
  //STATE

  const [isLoading, setIsLoading] = useState(true)
  const [pageError, setPageError] = useState(null)
  const [vm, setVm] = useState(null)
  const [images, setImages] = useState([null])

  //Wysiwyg
  // const [defaultEditorContent, setDefaultEditorContent] = useState(null)
  // const [defaultEditorState, setDefaultEditorState] = useState(null)

  //HOOKS

  const editorRef = useRef(null)
  const isMounted = useRef(true)
  let { id } = useParams()
  const isCreate = !id || id.toLowerCase() === "new"
  if (isCreate) id = null

  const { handleSubmit, watch, control, formState, reset } = useForm({
    defaultValues: vm, //|| pushNotificationDefaults,
  })
  const { isDirty, errors } = formState
  const { responseError } = useApiError()
  const history = useHistory()

  useEffect(() => {
    //Get page data on first load
    editEvent(id)
      .then(data => {
        if (!isMounted) return
        if (data.responseType === "SUCCESS") {
          //Success
          setVm(data)
          setImages(data.images)
          //wysiwyg
          // setDefaultEditorContent(data.content)
          // setDefaultEditorState(htmlToEditor(data.content))
        } else {
          if (data.responseMessage === "logout")
            window.location.href = "/Account/Login"
          throw new CodeError(data.responseMessage, 1)
        }
        setIsLoading(false)
      })
      .catch(err => {
        setPageError(err.message)
        setIsLoading(false)
      })
    return () => (isMounted.current = false)
  }, [])

  //EVENTS

  const handleCheckChange = (event, property) => {
    let newVm = { ...vm }
    newVm[property] = event.target.checked
    setVm(newVm)
  }

  async function onSubmit(formData) {
    //Check for missing dropdown as not on react-hook-form
    if (!vm.categoryId) {
      const newVm = { ...vm }
      newVm.categoryId_error = "Please select a category"
      setVm(newVm)
      return
    }

    //wysiwyg
    if (editorRef.current) {
      formData.content = editorRef.current.getContent()
    }
    // if (formData.content) formData.content = editorToHtml(formData.content)
    // else formData.content = defaultEditorContent

    //Check for empty content
    if (!formData.content || formData.content.trim() === "") {
      const newVm = { ...vm }
      newVm.content_error = "You must enter some content"
      setVm(newVm)
      return
    }

    setIsLoading(true)

    //Need to remove timezone offset from dates
    if (!!vm.dateFrom) {
      vm.dateFrom = new Date(vm.dateFrom)
      vm.dateFrom = new Date(
        vm.dateFrom.getTime() - vm.dateFrom.getTimezoneOffset() * 60000
      )
    }

    const response = await saveEvent({ ...vm, ...formData, images: images })

    if (!isMounted) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response)
    }

    history.goBack()

    //reset(response)
    //setIsLoading(false)
  }

  function handleError(error) {
    setIsLoading(false)
    responseError(error)
  }

  const handleListChange = (event, property) => {
    let newVm = { ...vm }
    newVm[property] = event.target.value
    newVm[property + "_error"] = null
    setVm(newVm)
  }

  const handleFromDateChange = newValue => {
    //Take the timezone away so it saves what you see
    let newVm = { ...vm }
    newVm.dateFrom = newValue
    // newVm[property + "_error"] = null
    setVm(newVm)
  }

  //RENDER

  if (isLoading) return "Loading..."

  if (pageError !== null) return <PageError>{pageError}</PageError>

  const titleField = (
    <TextField
      label="Event Title"
      name="title"
      defaultValue={vm.title}
      control={control}
      required
      error={errors.title?.message}
    />
  )

  const locationField = (
    <TextField
      label="Event Location"
      name="location"
      defaultValue={vm.location}
      control={control}
      error={errors.location?.message}
    />
  )

  const categoryField = (
    <>
      <StyledLabel className="mt-2">Event Category</StyledLabel>
      <DropdownList
        id="categoryId"
        items={vm.categories}
        value={vm.categoryId || ""}
        onChange={e => handleListChange(e, "categoryId")}
      />

      {vm.categoryId_error && <StyledError>{vm.categoryId_error}</StyledError>}
    </>
  )

  const repeatField = (
    <>
      <StyledLabel className="mt-3">How often?</StyledLabel>
      <DropdownList
        id="repeatFrequencyType"
        items={vm.repeatFrequencyTypes}
        value={vm.repeatFrequencyType || "0"}
        onChange={e => handleListChange(e, "repeatFrequencyType")}
      />

      {vm.repeatFrequencyType_error && (
        <StyledError>{vm.repeatFrequencyType_error}</StyledError>
      )}
    </>
  )

  const repeatFrequencyField = vm.repeatFrequencyType > "0" && (
    <TextField
      label={
        "Every how many " +
        vm.repeatFrequencyTypes
          .find(x => x.id == vm.repeatFrequencyType)
          .displayName.toLowerCase() +
        "?"
      }
      name="repeatFrequency"
      defaultValue={vm.repeatFrequency}
      control={control}
      required={vm.repeatFrequencyType > "0"}
      error={errors.repeatFrequency?.message}
    />
  )

  const fromDateField = (
    <>
      <StyledLabel className="mt-3 mb-3">Date of Event</StyledLabel>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          value={vm.dateFrom || dateWithoutTime(new Date())}
          onChange={handleFromDateChange}
        />
      </MuiPickersUtilsProvider>
    </>
  )

  const contentField = (
    <>
      <TinyMceEditor editorRef={editorRef} initialValue={vm.content} />

      {vm.content_error && <StyledError>{vm.content_error}</StyledError>}
    </>
  )

  const imagePicker = (
    <div className="ml-3 mb-3">
      {!images ||
        (images.length === 0 && (
          <StyledLabel className="mb-1">Images</StyledLabel>
        ))}
      <ImageList
        images={images}
        setImages={setImages}
        uploadFunction={uploadImage}
        lg="3"
      />
    </div>
  )

  const activeField = (
    <CheckboxWithLabel
      name="active"
      label="Active"
      control={control}
      defaultChecked={vm.active}
      onChange={e => handleCheckChange(e, "active")}
      className="pt-3"
    />
  )

  return (
    <Paper>
      <Row className="mb-3 align-items-center" noGutters>
        <Col>
          <h1 className="mb-0">{isCreate ? "Create" : "Edit"} Event</h1>
        </Col>
      </Row>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="12" lg="6">
            {titleField}
          </Col>
          <Col xs="12" lg="6" className="pl-4">
            {categoryField}
          </Col>
          <Col xs="12" lg="6">
            {locationField}
          </Col>
          <Col xs="12" className="pt-3 ">
            {imagePicker}
          </Col>
        </Row>

        <Row>
          <Col xs="12" lg="6">
            {fromDateField}
          </Col>
          <Col xs="12" lg="3">
            {repeatField}
          </Col>
          <Col xs="12" lg="3" className="mt-2">
            {repeatFrequencyField}
          </Col>
          <Col xs="12" className="mt-4">
            {contentField}
          </Col>
          <Col xs="12" className="mt-4">
            {activeField}
          </Col>
        </Row>

        <Row>
          <Col xs="auto" className="mt-3">
            <Button type="submit" isLoading={isLoading}>
              Save
            </Button>
          </Col>

          <Col xs="auto" className="mt-3">
            <BackButton>Back</BackButton>
          </Col>
        </Row>
      </form>
    </Paper>
  )
}

const StyledError = styled.div`
  color: red;
`

const StyledLabel = styled.div`
  font-size: 0.8rem;
`
