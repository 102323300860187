import React from "react"

function PageLoading({ width = 100, className = "", ...rest }) {
  return (
    <div className={`text-center ${className}`} {...rest}>
      <img
        src={require("assets/icons/loading.svg").default}
        alt="Loading..."
        style={{ width }}
      />
    </div>
  )
}

export default PageLoading
