import React, { useState, useRef } from "react"
import moment from "moment"
import { TextField as MdTextField } from "@material-ui/core"
import { Col, Row } from "reactstrap"
import { useSnackbar } from "notistack"
import { useHistory } from "react-router-dom"
import ConfirmDialog from "components/common/ConfirmDialog"
import Button from "components/common/Button"
import {
  sendTestPushNotification,
  sendPushNotification,
} from "services/pushService"
import useApiError from "hooks/useApiError"

function PushNotificationActions({ id, isDirty, sentOn }) {
  const testEmailsRef = useRef()
  const history = useHistory()
  const [confirmSend, setConfirmSend] = useState()
  const [confirmSendTest, setConfirmSendTest] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { responseError } = useApiError()

  async function handleSendTest() {
    const data = await sendTestPushNotification(id, testEmailsRef.current.value)

    if (data.responseType !== "SUCCESS") return responseError(data)

    enqueueSnackbar("Test push notification sent", {
      variant: "success",
      autoHideDuration: 9000,
    })
  }

  async function handleSend() {
    const data = await sendPushNotification(id)

    if (data.responseType !== "SUCCESS") return responseError(data)

    enqueueSnackbar("Push notification sent", {
      variant: "success",
      autoHideDuration: 9000,
    })
  }

  function showSend() {
    setConfirmSend({
      title: "Send Push Notification",
      content: "Are you sure you want to send this push notification?",
      handleClose: async function (confirmed) {
        setIsLoading(true)
        if (confirmed) {
          // Send the push notification
          await handleSend()
          // Reload the page
          history.go(0)
          // Exit function
          return
        }
        setConfirmSend(null)
        setIsLoading(false)
      },
    })
  }

  function showSendTest() {
    setConfirmSendTest({
      title: "Send Test Push Notification",
      content:
        "Enter one or more emails addresses split by a comma. Emails must match a valid user who has the app installed and push notifications enabled.",
      handleClose: async function (confirmed) {
        setIsLoading(true)
        if (confirmed) await handleSendTest()
        setConfirmSendTest(null)
        setIsLoading(false)
      },
    })
  }

  if (sentOn)
    return (
      <div className="text-right">
        Push notification was sent on: {moment(sentOn).format("D/M/YYYY HH:mm")}
      </div>
    )

  return (
    <>
      <ConfirmDialog properties={confirmSendTest} isLoading={isLoading}>
        <MdTextField
          inputRef={testEmailsRef}
          label="Test Emails"
          variant="outlined"
          defaultValue=""
          fullWidth
        />
      </ConfirmDialog>
      <ConfirmDialog properties={confirmSend} isLoading={isLoading} />

      <Row className="align-items-center">
        <Col>{isDirty && "Save changes to send"}</Col>
        <Col xs="auto">
          <Button type="button" disabled={isDirty} onClick={showSendTest}>
            Send Test
          </Button>
        </Col>
        <Col xs="auto">
          <Button type="button" disabled={isDirty} onClick={showSend}>
            Send
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default PushNotificationActions
