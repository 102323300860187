import React from "react"
import ReactDOM from "react-dom"
//import { ReactQueryDevtools } from "react-query-devtools"
import { BrowserRouter } from "react-router-dom"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import { SnackbarProvider } from "notistack"
import "react-toastify/dist/ReactToastify.css"
import "bootstrap-4-grid/css/grid.min.css"
import "@material/react-ripple/dist/ripple.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { muiTheme } from "utils/themeUtil"
import LayoutContextProvider from "context/LayoutContextProvider"

const theme = createMuiTheme(muiTheme)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LayoutContextProvider>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </LayoutContextProvider>
    </ThemeProvider>
    {/* Upcomment ReactQueryDevtools component to display the dev tools */}
    {/* <ReactQueryDevtools initialIsOpen /> */}
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
