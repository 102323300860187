import React, { useState } from "react"
import MaterialTable from "material-table"
import { useHistory } from "react-router-dom"
import { getPushNotificationMaterialTable } from "services/pushService"
import Button from "components/common/Button"
import { PageError } from "components/common/PageError"

function PushNotificationPage() {
  //STATE

  const [pageError, setPageError] = useState(null)

  //HOOKS

  const history = useHistory()

  //EVENTS

  const getQuery = async query => {
    var data = await getPushNotificationMaterialTable(query)
    if (data.indexOf && data.indexOf("<html>") > -1)
      window.location.href = "/Account/Login"
    if (data.responseType === "SUCCESS") return data
    setPageError(data.responseMessage)
  }

  //RENDER

  if (pageError !== null) return <PageError>{pageError}</PageError>

  return (
    <div className="mb-4">
      <div className="mb-3 text-right">
        <Button to="/push-notification/create">Create Push Notification</Button>
      </div>
      <MaterialTable
        title="Push Notifications"
        options={{ pageSize: 5, filtering: true }}
        data={getQuery}
        columns={[
          { title: "Title", field: "displayName" },
          {
            title: "Push Status",
            field: "pushNotificationStatus",
          },
          {
            title: "Created",
            field: "createdOn",
            filtering: false,
            defaultSort: "desc",
          },
        ]}
        onRowClick={(event, rowData) => {
          history.push(`/push-notification/${rowData.id}`)
        }}
      />
    </div>
  )
}

export default PushNotificationPage
