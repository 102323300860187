import http from "./httpService"

const apiEndpoint = "/Api/Messages"

export async function editMessage(articleId) {
  console.log("editMessage")
  const data = await http.get(apiEndpoint + "/editmessage", {
    params: { articleId: articleId },
  })
  console.log("editMessage", data)
  return data
}

export async function saveMessage(form) {
  console.log("saveMessage", form)
  const data = await http.post(apiEndpoint + "/savemessage", form)
  console.log("saveMessage", data)
  return data
}

export async function getMessagesMaterialTable(query) {
  const orderByField = query.orderBy?.field || null
  const { data } = await http.post(
    apiEndpoint + "/MaterialTable",
    {
      ...query,
      orderByField,
    },
    null,
    true
  )
  return data
}
