import React, { useEffect, useRef, useState } from "react"
import MaterialTable from "material-table"
import { useHistory } from "react-router-dom"
import {
  getArticlesPage,
  getArticlesMaterialTable,
} from "services/infoHubService"
import BackButton from "components/common/BackButton"
import Button from "components/common/Button"
import { CodeError, PageError } from "components/common/PageError"

export default function ArticlesPage() {
  //STATE

  const [isLoading, setIsLoading] = useState(true)
  const [pageError, setPageError] = useState(null)
  const [vm, setVm] = useState(null)

  //From ArticleTypeEnum
  // const [categoryTypes, setCategoryTypes] = useState({
  //   1: "Hub",
  //   5: "Homepage",
  // })

  //HOOKS

  const isMounted = useRef(true)
  const history = useHistory()

  useEffect(() => {
    //Get page data on first load
    getArticlesPage()
      .then(data => {
        if (!isMounted) return
        if (data.responseType === "SUCCESS") {
          //Success
          setVm(data)
        } else {
          if (data.responseMessage === "logout")
            window.location.href = "/Account/Login"
          throw new CodeError(data.responseMessage, 1)
        }
        setIsLoading(false)
      })
      .catch(err => {
        setPageError(err.message)
        setIsLoading(false)
      })
    return () => (isMounted.current = false)
  }, [])

  //EVENTS

  const getQuery = async query => {
    var data = await getArticlesMaterialTable(query)
    if (data.indexOf && data.indexOf("<html>") > -1)
      window.location.href = "/Account/Login"
    if (data.responseType === "SUCCESS") return data
    setPageError(data.responseMessage)
  }

  //RENDER

  if (isLoading) return "Loading..."

  if (pageError !== null) return <PageError>{pageError}</PageError>

  return (
    <>
      <div className="mb-4">
        <div className="mb-3 text-right">
          <BackButton>Back</BackButton>
          <Button className="ml-2" to="/article/new">
            Create Article
          </Button>
        </div>
        <MaterialTable
          title="Articles"
          options={{
            pageSize: 10,
            filtering: true,
            rowStyle: rowData => {
              if (!rowData.active) {
                return { color: "silver" }
              }
              return {}
            },
          }}
          data={getQuery}
          columns={[
            { title: "Title", field: "title" },
            { title: "Category", field: "categoryId", lookup: { 1: "Thing" } },
            { title: "Sequence", field: "sequence" },
            {
              title: "Type",
              field: "articleType",
              lookup: vm.articleTypes,
            },
          ]}
          onRowClick={(event, rowData) => {
            history.push(`/article/${rowData.id}`)
          }}
        />
      </div>
    </>
  )
}
