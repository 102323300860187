import React, { useState, useEffect, useContext } from "react"
import { Route } from "react-router-dom"
import authService from "services/authService"
import { LayoutDispatchContext } from "context/LayoutContextProvider"

// Used for testing
// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms))
// }

function ProtectedRoute({ path, component: Component, render, ...rest }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const layoutDispatch = useContext(LayoutDispatchContext)

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        // If we don't have a JWT saved in local storage, request it from the server
        if (!authService.getCurrentUser()) {
          const response = await authService.login()

          if (!response.token) {
            window.location.replace(`/account/login?returnUrl=${path}`)
            return false
          }

          window.location.replace(path)
        }

        // We already have a JWT in local storage, show the layout and route to the page
        layoutDispatch({ type: "SHOW_LAYOUT" })
        setIsAuthenticated(true)
      } catch (error) {
        // If we get an error, show the layout and redirect to login page.
        // Note that if the login page succeeds and the error is in the JS then this will
        // constantly redirect - needs to be looked at
        //layoutDispatch({ type: "SHOW_LAYOUT" })
        window.location.replace(
          `account/login?returnUrl=${path}&error=${error.message}`
        )
      }
    }

    authenticateUser()
  }, [])

  if (!isAuthenticated) {
    return null
  }

  return (
    <Route
      {...rest}
      render={props => {
        return Component ? <Component {...props} /> : render(props)
      }}
    />
  )
}

export default ProtectedRoute
