import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { CodeError, PageError } from "components/common/PageError"
import { editCategory, saveCategory } from "services/infoHubService"
import Paper from "components/common/Paper"
import TextField from "components/common/TextField"
import Button from "components/common/Button"
import useApiError from "hooks/useApiError"
import BackButton from "components/common/BackButton"
import CheckboxWithLabel from "components/common/CheckboxWithLabel"
import DropdownList from "components/common/DropdownList"
import styled from "styled-components"

export default function EditCategoryPage() {
  //STATE

  const [isLoading, setIsLoading] = useState(true)
  const [pageError, setPageError] = useState(null)
  const [vm, setVm] = useState(null)

  //HOOKS

  const isMounted = useRef(true)
  let { id } = useParams()
  const isCreate = !id || id.toLowerCase() === "new"
  if (isCreate) id = null

  const { handleSubmit, watch, control, formState, reset } = useForm()
  const { isDirty, errors } = formState
  const { responseError } = useApiError()
  const history = useHistory()

  useEffect(() => {
    //Get page data on first load
    editCategory(id)
      .then(data => {
        if (!isMounted) return
        if (data.responseType === "SUCCESS") {
          //Success
          setVm(data)
        } else {
          if (data.responseMessage === "logout")
            window.location.href = "/Account/Login"
          throw new CodeError(data.responseMessage, 1)
        }
        setIsLoading(false)
      })
      .catch(err => {
        setPageError(err.message)
        setIsLoading(false)
      })
    return () => (isMounted.current = false)
  }, [])

  //EVENTS

  async function onSubmit(formData) {
    setIsLoading(true)
    const response = await saveCategory({ ...vm, ...formData })

    if (!isMounted) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response)
    }

    history.goBack()

    //reset(response)
    //setIsLoading(false)
  }

  function handleError(error) {
    setIsLoading(false)
    responseError(error)
  }

  const handleCheckChange = (event, property) => {
    let newVm = { ...vm }
    newVm[property] = event.target.checked
    setVm(newVm)
  }

  const handleListChange = (event, property) => {
    let newVm = { ...vm }
    newVm[property] = event.target.value
    setVm(newVm)
  }

  //RENDER

  if (isLoading) return "Loading..."

  if (pageError !== null) return <PageError>{pageError}</PageError>

  return (
    <Paper>
      <Row className="mb-3 align-items-center" noGutters>
        <Col>
          <h1 className="mb-0">{isCreate ? "Create" : "Edit"} Category</h1>
        </Col>
      </Row>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="12" lg="6">
            <TextField
              label="Name"
              name="name"
              defaultValue={vm.name}
              control={control}
              required
              error={errors.name?.message}
            />
          </Col>
          <Col xs="12" lg="6" className="pl-4">
            <StyledLabel className="mt-1">
              Parent (if the category should sit beneath another)
            </StyledLabel>
            <DropdownList
              id="parentCategoryId"
              items={vm.parentCategories}
              value={vm.parentCategoryId || ""}
              onChange={e => handleListChange(e, "parentCategoryId")}
            />
          </Col>
          <Col xs="6" lg="3">
            <TextField
              label="Sequence"
              name="sequence"
              type="number"
              defaultValue={vm.sequence || "0"}
              control={control}
              error={errors.sequence?.message}
              required
            />
          </Col>
          <Col xs="6" lg="3">
            <StyledLabel className="mt-4">
              This is the order the category appears in the list (lowest to
              highest number)
            </StyledLabel>
          </Col>
          <Col xs="12" lg="6" className="pl-4">
            <StyledLabel className="mt-1">Type</StyledLabel>
            <DropdownList
              id="categoryType"
              items={vm.categoryTypes}
              value={vm.categoryType}
              onChange={e => handleListChange(e, "categoryType")}
            />
          </Col>
          <Col xs="12" lg="6" className="mt-3">
            <CheckboxWithLabel
              name="isFullWidth"
              label="Full Width"
              control={control}
              defaultChecked={vm.isFullWidth}
              onChange={e => handleCheckChange(e, "isFullWidth")}

            />
          </Col>
          <Col xs="12" lg="6" className="mt-3">
            <CheckboxWithLabel
              name="active"
              label="Active"
              control={control}
              defaultChecked={vm.active}
              onChange={e => handleCheckChange(e, "active")}

            />
          </Col>
        </Row>

        <Row>
          <Col xs="auto" className="mt-3">
            <Button type="submit" isLoading={isLoading}>
              Save
            </Button>
          </Col>

          <Col xs="auto" className="mt-3">
            <BackButton>Back</BackButton>
          </Col>
        </Row>
      </form>
    </Paper>
  )
}

const StyledLabel = styled.div`
  font-size: 0.9rem;
`
