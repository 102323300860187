// Colours
const black = "#000000"
const white = "#ffffff"
const blue = "#1E70B7"
const lightblue = "#00A09A"
const pink = "#D52184"
const purple = "#942FE4"
const navy = "#1D2B50"
const orange = "#ed6c36"
const red = "#741b2f"
const gold = "#a99766"

const primary = blue
const secondary = lightblue

const paperBackground = white
const formBackground = "#F8F8F8"

const deselectedSecondary = "#772669"
const deselectedWhite = "#C3C3C3"

const trafficLightGreen = "#0CAD43"
const trafficLightAmber = "#FFAD01"
const trafficLightRed = "#D20001"
const errorRed = "#D20001"
const errorLighter = "#FF3111"

// Gradients
const blueGradient = "linear-gradient(to bottom, #1E70B7 0%, #00417A 100%)"

// TODO: Review the below hex values for shades of grey and make sure we are happy with them.
const grey_100 = "#f2f2f2"
const grey_200 = "#d1d1d1"
const grey_300 = "#a5a5a5"
const grey_400 = "#737371"
const grey_500 = "#666666"
const grey_600 = "#5a5a5a"
const grey_700 = "#4d4d4d"
const grey_800 = "#141414"

// Breakpoints
const screen_xs_max = "575px"
const screen_sm_min = "576px"
const screen_sm_max = "767px"
const screen_md_min = "768px"
const screen_md_max = "991px"
const screen_lg_min = "992px"
const screen_lg_max = "1199px"
const screen_xl_min = "1200px"

// Fonts
const primaryFont = `'Roboto', sans-serif`

// Functions
const blackTransparent = (transparency = "0.5") =>
  `rgba(0, 0, 0, ${transparency})`

export default {
  black,
  white,
  blue,
  lightblue,
  pink,
  purple,
  navy,
  orange,
  gold,
  red,

  primary,
  secondary,

  blueGradient,

  paperBackground,
  formBackground,
  deselectedSecondary,
  deselectedWhite,

  trafficLightGreen,
  trafficLightAmber,
  trafficLightRed,
  errorRed,
  errorLighter,

  grey_100,
  grey_200,
  grey_300,
  grey_400,
  grey_500,
  grey_600,
  grey_700,
  grey_800,

  screen_xs_max,
  screen_sm_min,
  screen_sm_max,
  screen_md_min,
  screen_md_max,
  screen_lg_min,
  screen_lg_max,
  screen_xl_min,

  primaryFont,

  blackTransparent,
}
