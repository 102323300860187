import axios from "axios"
import { toast } from "react-toastify"
import logger from "services/logService"

// This interceptor handles unexpected errors and logs them, this only needs to be setup once
// and any unexpected errors will be logged.
axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500

  if (!expectedError) {
    // If this is an unexpected error then log it.
    logger.error(error)
    toast.error("An unexpected error occured.")
  }

  if (error.response.status === 401) console.log("User is unauthorized")

  return Promise.reject(error)
})

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt
}

//Assumes the calls always return a responseType and responseMessage
//Catches server errors and puts into responseType and responseMessage
//This means you only need to handle errors by inspecting those values (no try-catch needed)
async function axiosCall(type, fn, url, data, config, skipResponseCheck) {
  try {
    console.log("Axios Request", url, config)
    let response

    if (type === "GET" || type === "DEL") response = await fn(url, config)
    else response = await fn(url, data, config)

    console.log("Axios Response", response)
    if (skipResponseCheck) return response
    return checkResponse(response)
  } catch (error) {
    console.error("Axios Error", error)
    return {
      responseType: "EXCEPTION",
      responseMessage: String(error.message || error),
    }
  }
}

function checkResponse(response) {
  if (response.data && response.data.responseType) return response.data
  return {
    responseType: "EXCEPTION",
    responseMessage:
      response.data.indexOf("<html>") > -1
        ? "logout"
        : response.status +
          ": " +
          (!response.statusText || response.statusText === "undefined"
            ? "Something went wrong"
            : response.statusText),
  }
}

async function get(url, config, skipResponseCheck) {
  return axiosCall("GET", axios.get, url, null, config, skipResponseCheck)
}

async function post(url, data, config, skipResponseCheck) {
  return axiosCall("POST", axios.post, url, data, config, skipResponseCheck)
}

async function put(url, data, config, skipResponseCheck) {
  return axiosCall("PUT", axios.put, url, data, config, skipResponseCheck)
}

async function del(url, config, skipResponseCheck) {
  return axiosCall("DEL", axios.delete, url, null, config, skipResponseCheck)
}

export default {
  get,
  post,
  put,
  del,
  setJwt,
}
