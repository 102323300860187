import React from "react"
import BackButton from "./BackButton"
import styled from "styled-components"
import vars from "styles/variables"

/*

  General error display if something goes wrong loading a page

  To handle API response errors (e.g. performing actions on the page) use the hook useApiError instead
  
  Template for loading page data:

  import { CodeError, PageError } from "components/common/PageError"
  const [isLoading, setIsLoading] = useState(true)
  const [pageError, setPageError] = useState(null)
  const [vm, setVm] = useState(null)

  useEffect(() => {
    //Get page data on first load
    ApiCall()
    .then(data => {
    if (data.responseType === "SUCCESS") {
      //Success
      setVm(data)
    } else throw new CodeError(data.responseMessage, 1)
    setIsLoading(false)
    })
    .catch(err => {
    setPageError(err.message)
    setIsLoading(false)
    })
  }, [])

*/

export function PageError({ children }) {
  console.log("children", children)
  if (children.indexOf && children.indexOf("504") > -1)
    children = "You are not connected to the server"
  return (
    <StyledErrorBar>
      <div className="mb-5">An error has occurred</div>
      {children}
      <div className="mt-5">
        <BackButton>Back</BackButton>
      </div>
    </StyledErrorBar>
  )
}

//For throwing an error in JS
export class CodeError extends Error {
  constructor(message, code) {
    super(message)
    this.code = code
  }
}

export default { CodeError, PageError }

const StyledErrorBar = styled.div`
  background: ${vars.formBackground};
  font-size: 1.5rem;
  text-align: center;
  color: ${vars.errorRed};
  padding: 3.5rem;
  border: solid 1px ${vars.secondary};
  margin-top: 2rem;
`
