//import * as Sentry from "@sentry/browser";

function init() {
  // Sentry.init({
  //   dsn: "CHANGE_ME" // The Sentry dsn will look like this: https://088bbbcb6526432ebe7ee437ad63f6b8@sentry.io/1801342
  // });
}

function error(error) {
  // Sentry.captureException(error);
  console.error(error)
}

function info(message, obj) {
  console.log(message, obj)
}

// This is the interface of the login service, we are returning an object with two functions.
export default {
  init,
  error,
  info,
}
