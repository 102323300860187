import React from "react"
import { Editor } from "@tinymce/tinymce-react"
import config from "../../config"

//IMPORTANT: Create a new API key per client and add the config.js

/* Usage:

import TinyMceEditor from "components/common/TinyMceEditor"

const editorRef = useRef(null)

On submit:

if (editorRef.current) {
  formData.content = editorRef.current.getContent()
}

<TinyMceEditor editorRef={editorRef} initialValue={vm.content} />

*/

export default function TinyMceEditor({
  editorRef,
  initialValue,
  disabled,
  ...rest
}) {
  return (
    <Editor
      apiKey={config.tinyMceApiKey}
      disabled={disabled}
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={initialValue}
      init={{
        height: 500,
        menubar: false,
        convert_urls: false,
        relative_urls: false,
        remove_script_host: false,

        plugins: [
          "advlist anchor autolink autoresize charmap  codesample ",
          "fullscreen image link  lists paste ",
          "searchreplace  code help",
        ],
        toolbar:
          "formatselect bold italic underline forecolor emoticons | " +
          "bullist numlist | fullscreen | " +
          "table link codesample charmap | " +
          "alignleft aligncenter alignright alignjustify " +
          "| code help  ",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
    />
  )
}
