import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { Helmet } from "react-helmet"
import Layout from "components/ui/Layout"
import ProtectedRoute from "components/common/ProtectedRoute"
import HomePage from "pages/home/HomePage"
import LoginPage from "pages/account/LoginPage"
import NotFoundPage from "pages/error/NotFoundPage"
import AppAdminPage from "pages/home/AppAdminPage"
import EventsPage from "pages/events/EventsPage"
import EditEventPage from "pages/events/EditEventPage"
import CategoriesPage from "pages/info-hub/CategoriesPage"
import EditCategoryPage from "pages/info-hub/EditCategoryPage"
import ArticlesPage from "pages/info-hub/ArticlesPage"
import EditArticlePage from "pages/info-hub/EditArticlePage"
import MessagesPage from "pages/messages/MessagesPage"
import EditMessagePage from "pages/messages/EditMessagePage"
import PushNotificationPage from "pages/push-notifications/PushNotificationPage"
import UserManagementPage from "pages/user-management/UserManagementPage"
import EditUserPage from "pages/user-management/EditUserPage"
import PushNotificationDetailsPage from "pages/push-notifications/PushNotificationDetailsPage"
import CreatePushNotificationPage from "pages/push-notifications/CreatePushNotificationPage"

function App() {
  return (
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </Helmet>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <ProtectedRoute
          exact
          path="/push-notification/create"
          component={CreatePushNotificationPage}
        />
        <ProtectedRoute
          exact
          path="/push-notification/:id"
          component={PushNotificationDetailsPage}
        />

        <ProtectedRoute exact path="/events" component={EventsPage} />
        <ProtectedRoute exact path="/event/:id" component={EditEventPage} />

        <ProtectedRoute exact path="/categories" component={CategoriesPage} />
        <ProtectedRoute
          exact
          path="/category/:id"
          component={EditCategoryPage}
        />

        <ProtectedRoute exact path="/articles" component={ArticlesPage} />
        <ProtectedRoute exact path="/article/:id" component={EditArticlePage} />

        <ProtectedRoute exact path="/messages" component={MessagesPage} />
        <ProtectedRoute exact path="/message/:id" component={EditMessagePage} />

        <ProtectedRoute
          exact
          path="/push-notifications"
          component={PushNotificationPage}
        />
        <ProtectedRoute exact path="/user/:id" component={EditUserPage} />
        <ProtectedRoute
          exact
          path="/user-management"
          component={UserManagementPage}
        />
        <ProtectedRoute exact path="/app-admin" component={AppAdminPage} />
        <ProtectedRoute exact path="/" component={HomePage} />
        <Route to="/not-found" component={NotFoundPage} exact />
        <Redirect to="/not-found" />
      </Switch>
    </Layout>
  )
}

export default App
