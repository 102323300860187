import http from "./httpService"

const apiEndpoint = "/Api/PushNotifications"

export async function getPushNotification(id) {
  return await http.get(`${apiEndpoint}/${id}`)
}

export async function getPushNotificationReport(id) {
  return await http.get(`${apiEndpoint}/Report/${id}`)
}

export async function processPushNotificationTickets(id) {
  return await http.post(`${apiEndpoint}/ProcessTickets/${id}`)
}

export async function getPushNotificationMaterialTable(query) {
  const orderByField = query.orderBy?.field || null
  const { data } = await http.post(
    apiEndpoint + "/MaterialTable",
    {
      ...query,
      orderByField,
    },
    null,
    true
  )
  return data
}

export async function savePushNotification(formData) {
  // If we have an id, update push notification
  if (formData.id > 0)
    return await http.put(`${apiEndpoint}/${formData.id}`, formData)

  // If we dont have an id, create a push notification
  return await http.post(apiEndpoint, formData)
}

export async function sendTestPushNotification(id, testEmails) {
  return await http.post(`${apiEndpoint}/SendTest/${id}`, null, {
    params: { testEmails },
  })
}

export async function sendPushNotification(id) {
  return await http.post(`${apiEndpoint}/Send/${id}`)
}
