import React from "react"
import styled from "styled-components"
import vars from "styles/variables"
import { GrAttachment as AttachmentIcon } from "react-icons/gr"

function FilePickerButton({
  id,
  name,
  title,
  onFileChosen,
  text,
  disabled,
  ...rest
}) {
  return (
    <>
      <StyledInput
        id={id}
        name={name}
        type="file"
        disabled={disabled}
        onChange={onFileChosen}
        {...rest}
      />
      <StyledLabel
        title={title}
        htmlFor={id}
        disabled={disabled}
        className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary"
      >
        {!!text ? text : <AttachmentIcon />}
      </StyledLabel>
    </>
  )
}
export default FilePickerButton

const StyledInput = styled.input`
  //Essentially we want to hide the standard input
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const StyledLabel = styled.label`
  //These are competing with the MuiButton classes, hence marked as important
  //Making invisible instead of disabled until we can figure out how to use the material ui button disabled class
  padding: 0.6rem 1rem !important;
  color: ${vars.white} !important;
  display: ${props => (props.disabled ? "none" : "inline-flex")} !important;
  width: auto !important;
`
