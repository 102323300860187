import React, { useEffect } from "react"
import authService from "services/authService"

function LoginPage() {
  const handleLogin = async () => {
    try {
      // Log user in client side
      const response = await authService.login()
      // Response does not contain a JWT, redirect to login page.
      !response.token && window.location.replace(`account/login`)
      // Response contains JWT, user is logged in, redirect to home.
      response.token && window.location.replace("/")
    } catch (error) {
      throw new Error(error.message)
    }
  }

  useEffect(() => {
    handleLogin()
  }, [])

  return <h1>Logging in...</h1>
}

export default LoginPage
