import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { BiHelpCircle as HelpIcon } from "react-icons/bi"
//Local
import { CodeError, PageError } from "components/common/PageError"
import Paper from "components/common/Paper"
import TextField from "components/common/TextField"
import Button from "components/common/Button"
import useApiError from "hooks/useApiError"
import BackButton from "components/common/BackButton"
import { editArticle, saveArticle, uploadImage } from "services/infoHubService"
import CheckboxWithLabel from "components/common/CheckboxWithLabel"
import DropdownList from "components/common/DropdownList"
import ImageList from "components/hee/ImageList"
import styled from "styled-components"
import TinyMceEditor from "components/common/TinyMceEditor"
import PopupArticleHelp from "./PopupArticleHelp"

export default function EditArticlePage() {
  //STATE

  const [isLoading, setIsLoading] = useState(true)
  const [pageError, setPageError] = useState(null)
  const [vm, setVm] = useState(null)
  const [images, setImages] = useState([null])
  const [linkHasValue, setLinkHasValue] = useState(false)
  const [helpOpen, setHelpOpen] = useState(false)

  //HOOKS

  const editorRef = useRef(null)
  const isMounted = useRef(true)
  let { id } = useParams()
  const isCreate = !id || id.toLowerCase() === "new"
  if (isCreate) id = null

  const { handleSubmit, control, formState } = useForm()
  const { isDirty, errors } = formState
  const { responseError, showError } = useApiError()
  const history = useHistory()

  useEffect(() => {
    //Get page data on first load
    editArticle(id)
      .then(data => {
        if (!isMounted) return
        if (data.responseType === "SUCCESS") {
          //Success
          setVm(data)
          setImages(data.images)
          setLinkHasValue(!!data.link && data.link.trim().length > 0)
        } else {
          if (data.responseMessage === "logout")
            window.location.href = "/Account/Login"
          throw new CodeError(data.responseMessage, 1)
        }
        setIsLoading(false)
      })
      .catch(err => {
        setPageError(err.message)
        setIsLoading(false)
      })
    return () => (isMounted.current = false)
  }, [])

  //EVENTS

  async function onSubmit(formData) {
    //Check for missing dropdown as not on react-hook-form
    if (!vm.categoryId) {
      const newVm = { ...vm }
      newVm.categoryId_error = "Please select a category"
      setVm(newVm)
      return
    }

    //wysiwyg
    if (editorRef.current) {
      formData.content = editorRef.current.getContent()
    }

    //Check for empty content
    if (!linkHasValue) {
      if (!formData.content || formData.content.trim() === "") {
        const newVm = { ...vm }
        newVm.content_error = "You must enter some content"
        setVm(newVm)
        return
      }
    }

    //Save
    setIsLoading(true)
    const response = await saveArticle({ ...vm, ...formData, images: images })

    if (!isMounted) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response)
    }

    history.goBack()
  }

  function handleError(error) {
    setIsLoading(false)
    responseError(error)
  }

  const handleCheckChange = (event, property) => {
    let newVm = { ...vm }
    newVm[property] = event.target.checked
    setVm(newVm)
  }

  const handleListChange = (event, property) => {
    let newVm = { ...vm }
    newVm[property] = event.target.value
    newVm[property + "_error"] = null
    setVm(newVm)
  }

  const handleLinkChange = e => {
    const hasValue = !!e.target.value && e.target.value.trim().length > 0
    if (hasValue !== linkHasValue) setLinkHasValue(hasValue)
  }

  //RENDER

  if (isLoading) return "Loading..."

  if (pageError !== null) return <PageError>{pageError}</PageError>

  if (!isMounted) return

  const titleField = (
    <TextField
      label="Title"
      name="title"
      defaultValue={vm.title}
      control={control}
      required
      error={errors.title?.message}
    />
  )

  const linkField = (
    <TextField
      label="Link"
      name="link"
      title="This will link to an external page rather than showing content"
      defaultValue={vm.link}
      control={control}
      onBlur={e => handleLinkChange(e)}
      error={errors.link?.message}
    />
  )

  const categoryField = (
    <>
      <DropdownList
        id="categoryId"
        items={vm.parentCategories}
        value={vm.categoryId || ""}
        onChange={e => handleListChange(e, "categoryId")}
      />
      {vm.categoryId_error && <StyledError>{vm.categoryId_error}</StyledError>}
    </>
  )

  const sequenceField = (
    <TextField
      label="Sequence"
      name="sequence"
      type="number"
      defaultValue={vm.sequence + ""}
      control={control}
      error={errors.sequence?.message}
    />
  )

  const sequenceLabel = (
    <StyledLabel className="mt-4">
      This is the order the article appears in the list (lowest to highest
      number)
    </StyledLabel>
  )

  const imagePicker = vm.id > 0 && (
    <ImageList
      images={images}
      setImages={setImages}
      uploadFunction={uploadImage}
    />
  )

  const contentField = linkHasValue ? null : (
    <>
      <TinyMceEditor editorRef={editorRef} initialValue={vm.content} />
      {vm.content_error && <StyledError>{vm.content_error}</StyledError>}
    </>
  )

  const activeField = (
    <CheckboxWithLabel
      name="active"
      label="Active"
      control={control}
      defaultChecked={vm.active}
      onChange={e => handleCheckChange(e, "active")}
      className="pt-3"
    />
  )

  return (
    <Paper>
      <Row className="mb-3 align-items-center" noGutters>
        <Col xs="6">
          <h1 className="mb-0">{isCreate ? "Create" : "Edit"} Article</h1>
        </Col>
        <Col xs="6" className="d-flex justify-content-end">
          <StyledHelpIcon
            title="Article help"
            onClick={() => setHelpOpen(true)}
          />
        </Col>
      </Row>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="12" lg="6">
            <Row>
              <Col xs="12" lg="8">
                {titleField}
              </Col>
              <Col xs="12" lg="12" className="pl-4 mb-4">
                {categoryField}
              </Col>
              <Col xs="6" lg="3">
                {sequenceField}
              </Col>
              <Col xs="6" lg="5">
                {sequenceLabel}
              </Col>
              <Col xs="12">{linkField}</Col>
            </Row>
          </Col>
          <Col xs="12" lg="6" className="pt-3">
            {imagePicker}
          </Col>

          <Col xs="12 mt-3">{contentField}</Col>

          <Col xs="12" lg="12">
            {activeField}
          </Col>
        </Row>

        <Row>
          <Col xs="auto" className="mt-3">
            <Button type="submit" isLoading={isLoading}>
              Save
            </Button>
          </Col>

          <Col xs="auto" className="mt-3">
            <BackButton>Back</BackButton>
          </Col>
        </Row>
      </form>
      <PopupArticleHelp isOpen={helpOpen} onClose={() => setHelpOpen(false)} />
    </Paper>
  )
}

const StyledLabel = styled.div`
  font-size: 0.9rem;
`
const StyledError = styled.div`
  color: red;
`
const StyledHelpIcon = styled(HelpIcon)`
  font-size: 30px;
  cursor: pointer;
`
