import React from "react"
import { Controller } from "react-hook-form"
import { TextField as MdTextField } from "@material-ui/core"

function TextField({
  label,
  name,
  defaultValue,
  control,
  rules,
  required,
  variant = "outlined",
  margin = "normal",
  fullWidth = true,
  error,
  helperText,
  readOnly = false,
  ...rest
}) {
  const fieldRequired =
    rules?.required || required ? `${label} is a required field` : false
  const customRules = { ...rules, required: fieldRequired }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      rules={customRules}
      render={({ field }) => (
        <MdTextField
          {...field}
          label={label}
          variant={variant}
          margin={margin}
          fullWidth={fullWidth}
          error={!!error}
          helperText={error ? error : helperText ? helperText : null}
          InputProps={{
            readOnly,
          }}
          {...rest}
        />
      )}
    />
  )
}

export default TextField
