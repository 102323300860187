import React from "react"
import { Col, Row } from "reactstrap"

function NotFoundPage() {
  return (
    <Row>
      <Col>
        <h1>404: Page Not Found</h1>
      </Col>
    </Row>
  )
}

export default NotFoundPage
