import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import vars from "styles/variables"

function Card({ children, to, Icon }) {
  return (
    <StyledCard to={to}>
      {Icon && (
        <StyledIcon>
          <Icon />
        </StyledIcon>
      )}
      <StyledCardContent>{children}</StyledCardContent>
    </StyledCard>
  )
}

export default Card

const StyledCard = styled(Link)`
  background: ${vars.white};
  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.15);
  width: 100%;
  display: block;
  margin-bottom: 1rem;
`

const StyledCardContent = styled.div`
  padding: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
`

const StyledIcon = styled.div`
  background: ${vars.blueGradient};
  padding: 1rem 0;
  text-align: center;
  color: ${vars.white};
  svg {
    font-size: 8rem;
  }
`
