import React, { useState } from "react"
import MaterialTable from "material-table"
import { useHistory } from "react-router-dom"
import { getCategoriesMaterialTable } from "services/infoHubService"
import BackButton from "components/common/BackButton"
import Button from "components/common/Button"
import { PageError } from "components/common/PageError"

export default function CategoriesPage() {
  //STATE

  const [pageError, setPageError] = useState(null)

  //From CategoryTypeEnum
  const [categoryTypes, setCategoryTypes] = useState({
    1: "Hub",
    2: "Event",
  })

  //HOOKS

  const history = useHistory()

  //EVENTS

  const getQuery = async query => {
    var data = await getCategoriesMaterialTable(query)
    if (data.indexOf && data.indexOf("<html>") > -1)
      window.location.href = "/Account/Login"
    if (data.responseType === "SUCCESS") return data
    setPageError(data.responseMessage)
  }

  //RENDER

  if (pageError !== null) return <PageError>{pageError}</PageError>

  return (
    <div className="mb-4">
      <div className="mb-3 text-right">
        <BackButton>Back</BackButton>
        <Button className="ml-2" to="/category/new">
          Create Category
        </Button>
      </div>
      <MaterialTable
        title="Categories"
        options={{
          pageSize: 10,
          filtering: true,
          rowStyle: rowData => {
            if (!rowData.active) {
              return { color: "grey" }
            }
            return {}
          },
        }}
        data={getQuery}
        columns={[
          {
            title: "Type",
            field: "categoryType",
            lookup: { 0: "Misc", 1: "Hub", 2: "Event" },
          },
          { title: "Name", field: "name" },
          { title: "Sequence", field: "sequence" },
          { title: "Parent", field: "parent" },
        ]}
        onRowClick={(event, rowData) => {
          history.push(`/category/${rowData.id}`)
        }}
      />
    </div>
  )
}
