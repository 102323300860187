import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { getPushNotification } from "services/pushService"
import Paper from "components/common/Paper"
import PushNotificationForm from "components/ui/PushNotificationForm"
import PushNotificationReport from "components/ui/PushNotificationReport"
import useApiError from "hooks/useApiError"
import PageLoading from "components/common/PageLoading"

function PushNotificationDetailsPage() {
  const { id } = useParams()
  const isMounted = useRef(true)
  const [pushNotification, setPushNotification] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { responseError } = useApiError()

  useEffect(() => {
    onMount()
    return () => {
      isMounted.current = false
    }
  }, [])

  async function onMount() {
    const data = await getPushNotification(id)

    if (!isMounted) return
    if (data.responseType !== "SUCCESS") return handleError(data)

    setPushNotification(data)
    setIsLoading(false)
  }

  function handleError(error) {
    setIsLoading(false)
    responseError(error)
  }

  if (isLoading) return <PageLoading className="mt-4" />

  return (
    <Paper className="mb-4">
      <PushNotificationForm data={pushNotification} />
      {pushNotification?.sentOn && (
        <>
          <hr className="my-4" />
          <PushNotificationReport id={pushNotification.id} />
        </>
      )}
    </Paper>
  )
}

export default PushNotificationDetailsPage
