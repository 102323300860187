import http from "./httpService"

const apiEndpoint = "/api/user"

export async function getUsersPage() {
  console.log("getUsersPage")
  const data = await http.get(apiEndpoint + "/userspage", {
    params: {},
  })
  console.log("getUsersPage", data)
  return data
}

export async function getUsersMaterialTable(query) {
  console.log("Get Users Query", query)
  const orderByField = query.orderBy?.field || null
  const { data } = await http.post(
    apiEndpoint + "/usersmaterialtable",
    { ...query, orderByField },
    null,
    true
  )
  console.log("getUsers", data)
  return data
}

export async function editUser(id) {
  console.log("editUser", id)
  const data = await http.get(apiEndpoint + "/editUser", { params: { id: id } })
  console.log("editUser", data)
  return data
}

export async function saveUser(form) {
  console.log("saveUser", form)
  const data = await http.post(apiEndpoint + "/saveuser", form)
  console.log("saveUser", data)
  return data
}

export async function sendWelcomeEmail(userId) {
  console.log("sendWelcomeEmail", userId)
  const data = await http.get(apiEndpoint + "/sendwelcomeemail", {
    params: { id: userId },
  })
  console.log("sendWelcomeEmail", data)
  return data
}

export default {
  getUsersPage,
  getUsersMaterialTable,
  saveUser,
  sendWelcomeEmail,
}
