import React, { useReducer, createContext } from "react"

export const LayoutStateContext = createContext()
export const LayoutDispatchContext = createContext()

const initialState = {
  isLayoutHidden: true,
}

function reducer(state, action) {
  switch (action.type) {
    case "SHOW_LAYOUT": {
      return {
        ...state,
        isLayoutHidden: false,
      }
    }
    case "HIDE_LAYOUT": {
      return {
        ...state,
        isLayoutHidden: true,
      }
    }
    default:
      throw new Error(`Invalid action for navigation context: ${action.type}`)
  }
}

function LayoutContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  )
}

export default LayoutContextProvider
