import React from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

/* Sample usage:

    <DropdownList
      id="owned-by-role-list"
      items={vm.roles}
      value={vm.ownedByRoleId}
      onChange={e => handleListChange(e, "ownedByRoleId")}
    />

    Change handler (can be shared by multiple lists on the same page):

    const handleListChange = (event, property) => {
    let newVm = { ...vm }
    newVm[property] = event.target.value
    setVm(newVm)
  }

*/

function DropdownList({
  id,
  label,
  value,
  items,
  onChange,
  minWidth,
  disabled,
  codeProperty = "id",
  displayProperty = "displayName",
}) {
  return (
    <FormControl style={{ minWidth: minWidth }}>
      <InputLabel id={id + "-label"}>{label}</InputLabel>
      <Select
        labelId={id + "-label"}
        id={id}
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
      >
        {items.map(i => {
          return (
            <MenuItem key={i[codeProperty]} value={i[codeProperty]}>
              {i[displayProperty]}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default DropdownList
