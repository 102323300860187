import vars from "styles/variables"

export const muiTheme = {
  palette: {
    primary: {
      main: vars.primary,
    },
    secondary: {
      main: vars.secondary,
    },
    white: {
      main: vars.white,
    },
  },
  overrides: {
    MuiButton: {
      text: {
        color: "white",
      },
      outlinedPrimary: {
        border: `2px solid ${vars.primary}`,
        "&:hover": {
          border: `2px solid ${vars.primary}`,
        },
      },
      outlinedSecondary: {
        border: `2px solid ${vars.secondary}`,
        "&:hover": {
          border: `2px solid ${vars.secondary}`,
        },
      },
    },
  },
}
