import React, { useState } from "react"
import MaterialTable from "material-table"
import { useHistory } from "react-router-dom"
import { getEventsMaterialTable } from "services/eventsService"
import BackButton from "components/common/BackButton"
import Button from "components/common/Button"
import { PageError } from "components/common/PageError"

export default function EventsPage() {
  //STATE

  const [pageError, setPageError] = useState(null)

  //HOOKS

  const history = useHistory()

  //EVENTS

  const getQuery = async query => {
    var data = await getEventsMaterialTable(query)
    if (data.indexOf && data.indexOf("<html>") > -1)
      window.location.href = "/Account/Login"
    if (data.responseType === "SUCCESS") return data
    setPageError(data.responseMessage)
  }

  //RENDER

  if (pageError !== null) return <PageError>{pageError}</PageError>

  return (
    <div className="mb-4">
      <div className="mb-3 text-right">
        <BackButton>Back</BackButton>
        <Button className="ml-2" to="/event/new">
          Create Event
        </Button>
      </div>
      <MaterialTable
        title="Events"
        options={{
          pageSize: 10,
          filtering: true,
          rowStyle: rowData => {
            if (!rowData.active) {
              return { color: "silver" }
            }
            return {}
          },
        }}
        data={getQuery}
        columns={[
          { title: "Title", field: "title" },
          {
            title: "Start Date",
            field: "dateFrom",
          },
          {
            title: "Repeats",
            field: "repeats",
          },
        ]}
        onRowClick={(event, rowData) => {
          history.push(`/event/${rowData.id}`)
        }}
      />
    </div>
  )
}
