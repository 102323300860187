import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { Col, Row } from "reactstrap"
//Wysiwyg
// import {
//   RichEditor,
//   htmlToEditor,
//   editorToHtml,
// } from "components/common/RichEditor"
//Local
import { CodeError, PageError } from "components/common/PageError"
import Paper from "components/common/Paper"
import TextField from "components/common/TextField"
import Button from "components/common/Button"
import useApiError from "hooks/useApiError"
import BackButton from "components/common/BackButton"
import { editMessage, saveMessage } from "services/messagesService"
import { uploadImage } from "services/infoHubService"
import ImageList from "components/hee/ImageList"
import TinyMceEditor from "components/common/TinyMceEditor"
import styled from "styled-components"

export default function EditMessagePage() {
  //STATE

  const [isLoading, setIsLoading] = useState(true)
  const [pageError, setPageError] = useState(null)
  const [vm, setVm] = useState(null)
  const [images, setImages] = useState([null])
  //Wysiwyg
  // const [defaultEditorContent, setDefaultEditorContent] = useState(null)
  // const [defaultEditorState, setDefaultEditorState] = useState(null)

  //HOOKS

  const editorRef = useRef(null)
  const isMounted = useRef(true)
  let { id } = useParams()
  const isCreate = !id || id.toLowerCase() === "new"
  if (isCreate) id = null

  const { handleSubmit, control, formState } = useForm()
  const { isDirty, errors } = formState
  const { responseError } = useApiError()
  const history = useHistory()

  useEffect(() => {
    //Get page data on first load
    editMessage(id)
      .then(data => {
        if (!isMounted) return
        if (data.responseType === "SUCCESS") {
          //Success
          setVm(data)
          setImages(data.images)
          //Wysiwyg
          // setDefaultEditorContent(data.content)
          // setDefaultEditorState(htmlToEditor(data.content))
        } else {
          if (data.responseMessage === "logout")
            window.location.href = "/Account/Login"
          throw new CodeError(data.responseMessage, 1)
        }
        setIsLoading(false)
      })
      .catch(err => {
        setPageError(err.message)
        setIsLoading(false)
      })
    return () => (isMounted.current = false)
  }, [])

  //EVENTS

  async function onSubmit(formData) {
    if (!isCreate) return

    //Wysiwyg
    if (editorRef.current) {
      formData.content = editorRef.current.getContent()
    }
    // if (formData.content) formData.content = editorToHtml(formData.content)
    // else formData.content = defaultEditorContent

    //Check for empty content
    if (!formData.content || formData.content.trim() === "") {
      const newVm = { ...vm }
      newVm.content_error = "You must enter some content"
      setVm(newVm)
      return
    }

    setIsLoading(true)
    const response = await saveMessage({ ...vm, ...formData, images: images })

    if (!isMounted) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response)
    }

    history.goBack()

    //reset(response)
    //setIsLoading(false)
  }

  function handleError(error) {
    setIsLoading(false)
    responseError(error)
  }

  //RENDER

  if (isLoading) return "Loading..."

  if (pageError !== null) return <PageError>{pageError}</PageError>

  return (
    <Paper>
      <Row className="mb-3 align-items-center" noGutters>
        <Col>
          <h1 className="mb-0">{isCreate ? "Create" : "View Sent"} Message</h1>
        </Col>
      </Row>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="12" lg="6">
            <TextField
              label="Title"
              name="title"
              defaultValue={vm.title}
              control={control}
                required
                rules={{
                    required: true,
                    maxLength: {
                        value: 44,
                        message: "Max title length 44 characters",
                    }
                }}
              disabled={!isCreate}
              error={errors.title?.message}
            />
          </Col>

          <Col xs="12" lg="6" className="pt-3">
            <ImageList
              images={images}
              setImages={setImages}
              uploadFunction={uploadImage}
              readOnly={!isCreate}
            />
          </Col>

          <Col xs="12">
            <TinyMceEditor
              disabled={!isCreate}
              editorRef={editorRef}
              initialValue={vm.content}
            />
            {vm.content_error && <StyledError>{vm.content_error}</StyledError>}
          </Col>
        </Row>

        <Row>
          {isCreate && (
            <Col xs="auto" className="mt-3">
              <Button type="submit" isLoading={isLoading}>
                {isCreate ? "Send" : "Update"}
              </Button>
            </Col>
          )}

          <Col xs="auto" className="mt-3">
            <BackButton>Back</BackButton>
          </Col>
        </Row>
      </form>
    </Paper>
  )
}

const StyledError = styled.div`
  color: red;
`
