import React from "react"
import styled from "styled-components"
import vars from "styles/variables"

function Paper({ children, ...rest }) {
  return <StyledPaper {...rest}>{children}</StyledPaper>
}

export default Paper

const StyledPaper = styled.div`
  padding: 1.5rem;
  background: ${vars.paperBackground};
  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.15);
`
