import React, { useEffect, useState, useRef } from "react"
import { Col, Row } from "reactstrap"
//Icons
import { ImBin2 as RemoveIcon } from "react-icons/im"
import { FaArrowAltCircleDown as DownIcon } from "react-icons/fa"
import { FaArrowAltCircleUp as UpIcon } from "react-icons/fa"
//Local
import FilePickerButton from "components/common/FilePickerButton"
import useApiError from "hooks/useApiError"
import styled from "styled-components"

export default function ImageList({
  uploadFunction, //API call to handle the upload
  images,
  setImages, //State is handled by to the caller
  isReadOnly,
  lg = "6",
}) {
  //STATE

  const [isUploading, setIsUploading] = useState(false)

  //HOOKS

  const { responseError, showError } = useApiError()

  //EVENTS

  const handleMoveAttachment = (i, direction) => {
    if (i + direction < 0 || i + direction >= images.length) return
    let newImages = [...images]
    const nextImage = newImages[i + direction]
    newImages[i + direction] = newImages[i]
    newImages[i] = nextImage
    setImages(newImages)
  }

  const handleRemoveAttachment = i => {
    let newImages = [...images]
    newImages.splice(i, 1)
    setImages(newImages)
  }

  const handleUploadAttachment = e => {
    const files = Array.from(e.target.files)
    setIsUploading(true)

    const formData = new FormData()

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i])
      formData.append("fileNames", files[i].name)
    }

    uploadFunction(formData)
      .then(data => {
        if (!responseError(data)) {
          setIsUploading(false)
          let newImages = [...images]
          newImages = newImages.concat(data.images)
          setImages(newImages)
        }
      })
      .catch(err => {
        console.log("catch", err)
        showError(err.message)
      })
    e.target.value = null
  }

  //RENDER

  const imageList =
    !images || images.length === 0
      ? null
      : images.map((image, i) => {
          return (
            <Col key={image.id} xs="6" lg={lg} className="p-0 pl-1">
              <StyledThumbnail src={image.imageLocation} />
              {!isReadOnly && (
                <Row className="mb-2">
                  <Col>
                    {i > 0 && (
                      <StyledUpIcon
                        title="Move up in the sequence"
                        onClick={() => handleMoveAttachment(i, -1)}
                        className="mr-2"
                      />
                    )}
                    {i < images.length - 1 && (
                      <StyledDownIcon
                        title="Move down in the sequence"
                        onClick={() => handleMoveAttachment(i, 1)}
                      />
                    )}
                  </Col>
                  <Col className="d-flex flex-row-reverse">
                    <StyledRemoveIcon
                      onClick={() => handleRemoveAttachment(i)}
                      title="Remove this image"
                    />
                  </Col>
                </Row>
              )}
            </Col>
          )
        })

  return (
    <Row>
      {imageList}
      <Col className="p-0 pl-1">
        {isUploading ? (
          <span>Uploading...</span>
        ) : isReadOnly ? null : (
          <FilePickerButton
            id="imageUpload"
            name="file"
            multiple
            title="Add images"
            className="mt-2"
            onFileChosen={handleUploadAttachment}
          />
        )}
      </Col>
    </Row>
  )
}

const StyledThumbnail = styled.img`
  max-width: 100%;
`

const StyledDownIcon = styled(DownIcon)`
  color: darkgreen;
  font-size: 1.5rem;
  cursor: pointer;
`

const StyledUpIcon = styled(UpIcon)`
  color: darkgreen;
  font-size: 1.5rem;
  cursor: pointer;
`

const StyledRemoveIcon = styled(RemoveIcon)`
  color: darkred;
  font-size: 1.5rem;
  cursor: pointer;
`
