import http from "./httpService"

const apiEndpoint = "/Api/Events"

export async function getEventsMaterialTable(query) {
  const orderByField = query.orderBy?.field || null
  const { data } = await http.post(
    apiEndpoint + "/MaterialTable",
    {
      ...query,
      orderByField,
    },
    null,
    true
  )
  return data
}

export async function editEvent(eventId) {
  console.log("editEvent")
  const data = await http.get(apiEndpoint + "/editevent", {
    params: { eventId: eventId },
  })
  console.log("editEvent", data)
  return data
}

export async function saveEvent(form) {
  console.log("saveEvent", form)
  const data = await http.post(apiEndpoint + "/saveevent", form)
  console.log("saveEvent", data)
  return data
}
