import React, { useContext } from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Container } from "reactstrap"
import Header from "components/ui/Header"
import Footer from "components/ui/Footer"
import { LayoutStateContext } from "context/LayoutContextProvider"
import vars from "styles/variables"

function Layout({ children }) {
  const layoutState = useContext(LayoutStateContext)
  return (
    <LayoutContainer>
      <GlobalStyle />
      {layoutState.isLayoutHidden || <Header />}
      <main>
        <Container>{children}</Container>
      </main>
      {layoutState.isLayoutHidden || <Footer />}
    </LayoutContainer>
  )
}

export default Layout

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    font-family: ${vars.primaryFont};
    background: ${vars.grey_100};
  }
  
  main{
    flex-grow: 1;
  }

  h1,h2,h3{
    margin-top: 0;
    font-weight: 400;
  }

  a{
    text-decoration: none;
    color: ${vars.black};
  }

  p{
    margin-top: 0;
  }
`

const LayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
