import http from "./httpService"

const apiEndpoint = "/Api/InformationHub"

export async function getCategoriesMaterialTable(query) {
  const orderByField = query.orderBy?.field || null
  const { data } = await http.post(
    apiEndpoint + "/MaterialTable/Categories",
    {
      ...query,
      orderByField,
    },
    null,
    true
  )
  return data
}

export async function editCategory(categoryId) {
  console.log("editCategory")
  const data = await http.get(apiEndpoint + "/editcategory", {
    params: { categoryId: categoryId },
  })
  console.log("editCategory", data)
  return data
}

export async function saveCategory(form) {
  console.log("saveCategory", form)
  const data = await http.post(apiEndpoint + "/editcategory", form)
  console.log("saveCategory", data)
  return data
}

export async function getArticlesPage() {
  console.log("getArticlesPage")
  const data = await http.get(apiEndpoint + "/articlespage", {
    params: {},
  })
  console.log("getArticlesPage", data)
  return data
}

export async function getArticlesMaterialTable(query) {
  const orderByField = query.orderBy?.field || null
  const { data } = await http.post(
    apiEndpoint + "/MaterialTable/Articles",
    {
      ...query,
      orderByField,
    },
    null,
    true
  )
  return data
}

export async function editArticle(articleId) {
  console.log("editArticle")
  const data = await http.get(apiEndpoint + "/editarticle", {
    params: { articleId: articleId },
  })
  console.log("editArticle", data)
  return data
}

export async function saveArticle(form) {
  console.log("saveArticle", form)
  const data = await http.post(apiEndpoint + "/editarticle", form)
  console.log("saveArticle", data)
  return data
}

export async function uploadImage(form) {
  console.log("uploadImage", form)
  const data = await http.post("/api/images/upload", form)
  console.log("data", data)
  return data
}
