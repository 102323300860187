import React, { useState, useRef, useEffect } from "react"
import moment from "moment"
import { useForm } from "react-hook-form"
import { Col, Row } from "reactstrap"
import { withStyles } from "@material-ui/styles"
import { MdEdit, MdPeople } from "react-icons/md"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { savePushNotification } from "services/pushService"
/*import { ApplePushNotificationPreview } from "push-notification-preview"*/
import Button from "components/common/Button"
import TextField from "components/common/TextField"
import IconButton from "components/common/IconButton"
import vars from "styles/variables"
import PushNotificationActions from "components/ui/PushNotificationActions"
import useApiError from "hooks/useApiError"

//const styles = {
//  cardNotification: {
//    backgroundColor: vars.grey_100,
//  },
//}

//const StyledApplePushNotificationPreview = withStyles(styles)(
//  ApplePushNotificationPreview
//)

const pushNotificationDefaults = {
  displayName: "",
  messageTitle: "",
  messageBody: "",
}

function PushNotificationForm({ data, isCreate }) {
  const isMounted = useRef(true)
  const history = useHistory()
  const { responseError } = useApiError()
  const [isLoading, setIsLoading] = useState(false)
  const [isEditName, setIsEditName] = useState(!!isCreate)
  const { handleSubmit, watch, control, formState, reset } = useForm({
    defaultValues: data || pushNotificationDefaults,
  })
  const { isDirty, errors } = formState

  const previewName = watch("displayName")
  const previewTitle = watch("messageTitle")
  const previewMessage = watch("messageBody")

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  function toggleEditName() {
    setIsEditName(prev => !prev)
  }

  async function onSubmit(formData) {
    //console.log("formData", formData)
    setIsLoading(true)
    const response = await savePushNotification(formData)

    if (!isMounted) return
    if (response.responseType !== "SUCCESS") {
      if (response.responseMessage === "logout")
        window.location.href = "/Account/Login"
      return handleError(response)
    }

    if (isCreate) return history.push(`/push-notification/${response.id}`)

    reset(response)
    setIsEditName(false)
    setIsLoading(false)
  }

  function handleError(error) {
    setIsLoading(false)
    responseError(error)
  }

  return (
    <>
      <Row className="mb-3 align-items-center" noGutters>
        {isCreate && (
          <Col>
            <h1 className="mb-0">Create Push Notification</h1>
          </Col>
        )}
        {!isCreate && (
          <>
            <Col xs="auto">
              <h1 className="mb-0">{previewName}</h1>
            </Col>
            {!data?.sentOn && (
              <Col xs="auto">
                <IconButton color="primary" onClick={toggleEditName}>
                  <MdEdit />
                </IconButton>
              </Col>
            )}
          </>
        )}
      </Row>

      <Row>
        <Col xs="12" lg="6">
          <form onSubmit={handleSubmit(onSubmit)}>
            {isEditName && (
              <TextField
                label="Display Name"
                name="displayName"
                control={control}
                required
                error={errors.displayName?.message}
              />
            )}
            <TextField
              label="Title"
              name="messageTitle"
              control={control}
              error={errors.messageTitle?.message}
              rules={{
                required: true,
                maxLength: {
                  value: 44,
                  message: "Max title length 44 characters",
                },
              }}
              readOnly={!!data?.sentOn}
            />
            <TextField
              label="Message"
              name="messageBody"
              control={control}
              error={errors.messageBody?.message}
              rules={{
                required: true,
                maxLength: {
                  value: 462,
                  message: "Max message length 462 characters",
                },
              }}
              readOnly={!!data?.sentOn}
              rows={8}
              multiline
            />
            <Row>
              {!data?.sentOn && (
                <Col xs="auto" className="mt-3">
                  <Button type="submit" isLoading={isLoading}>
                    Save
                  </Button>
                </Col>
              )}
              <Col xs="auto" className="mt-3">
                <Button to="/push-notifications" variant="outlined">
                  Back
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
        <Col>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              {/*<StyledApplePushNotificationPreview*/}
              {/*  appName="Hee"*/}
              {/*  time={moment(data?.sentOn || new Date()).format("D/M/YY HH:mm")}*/}
              {/*  title={previewTitle || data?.messageTitle || ""}*/}
              {/*  message={previewMessage || data?.messageBody || ""}*/}
              {/*/>*/}
              {!isCreate && (
                <Row className="my-4">
                  <Col>
                    <p>
                      Status: <strong>{data.pushNotificationStatus}</strong>
                      {data.errorMessage && (
                        <StyledErrorMessage>
                          {data.errorMessage}
                        </StyledErrorMessage>
                      )}
                    </p>
                    <p>
                      Created By: <strong>{data.createdBy}</strong>
                    </p>
                  </Col>
                  <Col sm="auto">
                    <div className="d-flex align-items-center justify-content-end ">
                      <StyledPeopleIcon title="Total number of devices the push notification will send to" />{" "}
                      Contacts: {data.pushTokenCount}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            {!isCreate && (
              <PushNotificationActions
                id={data.id}
                sentOn={data?.sentOn}
                isDirty={isDirty}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default PushNotificationForm

const StyledPeopleIcon = styled(MdPeople)`
  font-size: 26px;
  margin-right: 10px;
`
const StyledErrorMessage = styled.span`
  color: ${vars.red};
  display: block;
  margin-top: 10px;
  font-weight: bold;
`
