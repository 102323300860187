import React from "react"
import { default as MdcIconButton } from "@material-ui/core/IconButton"

function IconButton({
  children,
  color = "default",
  component = "span",
  to,
  ...rest
}) {
  return (
    <MdcIconButton to={to} color={color} component={component} {...rest}>
      {children}
    </MdcIconButton>
  )
}

export default IconButton
