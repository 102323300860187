//import Cookies from "js-cookie";
import http from "./httpService"
import jwtDecode from "jwt-decode"

const apiEndpoint = "/account/authenticate"
const tokenKey = "token"

http.setJwt(getJwt())

export async function login() {
  const data = await http.get(apiEndpoint)

  // Local storage is available in all browsers for storing key value pairs
  data.jwtToken && localStorage.setItem(tokenKey, data.jwtToken)
  return data
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt)
}

export function logout() {
  // Remove the JWT from local storage, logging the user out of the client side app.
  localStorage.removeItem(tokenKey)
  // Clear any auth Cookies created by .net core by calling the logout endpoint.
  window.location.replace("/account/logoff")
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey)
    return jwtDecode(jwt)
  } catch (ex) {
    return null
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey)
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
}

// Cookie based storage for auth
// export function getCurrentUser() {
//   try {
//     const jwt = Cookies.get("Reacttoken");
//     console.log("JWT", jwt);
//     return jwtDecode(jwt);
//   } catch (ex) {
//     return null;
//   }
// }

// export function getJwt() {
//   return Cookies.get("Reacttoken");
// }

// export function logout() {
//   Cookies.remove("Reacttoken");
// }
