import React from "react"
import { Row, Col } from "reactstrap"
import {
  Category,
  NewReleases,
  CalendarToday,
  Email,
  SmsOutlined,
} from "@material-ui/icons"
import Card from "components/common/Card"

function AppAdminPage() {
  return (
    <>
      <h1>Manage Mobile App</h1>
      <Row>
        <Col sm="6" md="4">
          <Card to="/categories" Icon={Category}>
            Categories
          </Card>
        </Col>
        <Col sm="6" md="4">
          <Card to="/articles" Icon={NewReleases}>
            Articles
          </Card>
        </Col>
        <Col sm="6" md="4">
          <Card to="/events" Icon={CalendarToday}>
            Events
          </Card>
        </Col>
        <Col sm="6" md="4">
          <Card to="/messages" Icon={Email}>
            Messages
          </Card>
        </Col>
        <Col sm="6" md="4">
          <Card to="/push-notifications" Icon={SmsOutlined}>
            Push Notifications
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AppAdminPage
