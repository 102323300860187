import React, { useEffect, useRef, useState } from "react"
import {
  getUsersPage,
  getUsersMaterialTable,
  saveUser,
} from "services/userService"
import BackButton from "components/common/BackButton"
import Button from "components/common/Button"
import MaterialTable from "material-table"
import { useHistory } from "react-router-dom"
import { CodeError, PageError } from "components/common/PageError"
import useApiError from "hooks/useApiError"

function UserManagementPage() {
  //STATE

  const [accessGroups, setAccessGroups] = useState(null)
  const [entityStatusTypes, setEntityStatusTypes] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [pageError, setPageError] = useState(null)

  //HOOKS

  const history = useHistory()
  const tableRef = useRef()
  const { responseError, showError } = useApiError()

  //EVENTS

  useEffect(() => {
    //Get page data on first load
    getUsersPage()
      .then(data => {
        if (data.responseType === "SUCCESS") {
          //Success
          setAccessGroups(data.accessGroups)
          setEntityStatusTypes(data.entityStatusTypes)
          setIsLoading(false)
        } else {
          if (data.responseMessage === "logout")
            window.location.href = "/Account/Login"
          throw new CodeError(data.responseMessage, 1)
        }
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setPageError(err.message)
        setIsLoading(false)
      })
  }, [])

  const getQuery = async query => {
    var data = await getUsersMaterialTable(query)
    if (data.indexOf && data.indexOf("<html>") > -1)
      window.location.href = "/Account/Login"
    if (data.responseType === "SUCCESS") return data
    setPageError(data.responseMessage)
  }

  //EVENTS

  const saveUserRecord = form => {
    saveUser(form)
      .then(data => {
        if (!responseError(data)) {
          tableRef.current.onQueryChange()
        }
      })
      .catch(err => {
        console.log("Caught", err)
        showError(err.message)
      })
  }

  const isValid = data => {
    return !!data.firstName && !!data.surname && !!data.email
  }

  //RENDER

  if (isLoading) return "Loading..."

  if (pageError !== null) return <PageError>{pageError}</PageError>

  return (
    <>
      <div className="mb-3 text-right">
        <BackButton>Back</BackButton>
        <Button className="ml-2" to="/user/new">
          Create User
        </Button>
      </div>

      <MaterialTable
        title="User Management"
        tableRef={tableRef}
        options={{
          pageSize: 5,
          filtering: true,
          rowStyle: rowData => {
            if (rowData.entityStatusId !== 1) {
              return { color: "silver" }
            }
            return {}
          },
        }}
        columns={[
          { title: "First Name", field: "firstName" },
          { title: "Surname", field: "surname" },
          // { title: "Job Title", field: "jobTitle" },
          { title: "Email", field: "email", editable: "onAdd" },
          {
            title: "Access Group",
            field: "accessGroupId",
            lookup: accessGroups,
            editable: "onUpdate",
          },
          // {
          //   title: "Status",
          //   field: "entityStatusId",
          //   lookup: entityStatusTypes,
          //   editable: "onUpdate",
          // },
          {
            title: "Created",
            field: "createdDate",
            filtering: false,
            editable: "never",
          },
        ]}
        data={getQuery}
        onRowClick={(event, rowData) => {
          history.push(`/user/${rowData.id}`)
        }}
        // editable={{
        //   onRowAdd: newData =>
        //     new Promise((resolve, reject) => {
        //       if (!isValid(newData)) {
        //         showError("You must fill all fields to add a user")
        //         reject()
        //       }
        //       saveUserRecord({ ...newData })
        //       resolve()
        //     }),
        //   onRowUpdate: (newData, oldData) =>
        //     new Promise((resolve, reject) => {
        //       if (!isValid(newData)) {
        //         showError("You must fill all fields")
        //         reject()
        //       }
        //       saveUserRecord({ ...newData })
        //       resolve()
        //     }),
        // }}
      />
    </>
  )
}

export default UserManagementPage
