import React, { useEffect, useState, useRef } from "react"
import { Col, Row } from "reactstrap"
import styled from "styled-components"
import {
  getPushNotificationReport,
  processPushNotificationTickets,
} from "services/pushService"
import useApiError from "hooks/useApiError"
import vars from "styles/variables"
import PageLoading from "components/common/PageLoading"

function PushNotificationReport({ id }) {
  const isMounted = useRef(true)
  const [pushNotification, setPushNotification] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { responseError } = useApiError()

  useEffect(() => {
    onMount()
    return () => {
      isMounted.current = false
    }
  }, [])

  async function onMount() {
    await processPushNotificationTickets(id)
    const data = await getPushNotificationReport(id)

    if (!isMounted) return
    if (data.responseType !== "SUCCESS") return responseError(data)

    setPushNotification(data)
    setIsLoading(false)
  }

  if (isLoading) return <PageLoading width={40} />

  return (
    <>
      <h2>Push Notification Results</h2>
      <Row>
        <Col>
          <StyledBlock>
            <StyledValue>{pushNotification.sendCount}</StyledValue>
            <StyledText>Sent</StyledText>
          </StyledBlock>
        </Col>
        <Col>
          <StyledBlock>
            <StyledValue>{pushNotification.successCount}</StyledValue>
            <StyledText>Success</StyledText>
          </StyledBlock>
        </Col>
        <Col>
          <StyledBlock>
            <StyledValue>{pushNotification.failCount}</StyledValue>
            <StyledText>Fail</StyledText>
          </StyledBlock>
        </Col>
      </Row>
    </>
  )
}

export default PushNotificationReport

const StyledBlock = styled.div`
  background: ${vars.white};
  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.15);
  width: 100%;
  display: block;
`

const StyledValue = styled.div`
  background: ${vars.blueGradient};
  padding: 1rem 0;
  text-align: center;
  color: ${vars.white};
  font-size: 3rem;
`

const StyledText = styled.div`
  padding: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
  background: ${vars.grey_100};
`
