import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "components/common/Button"
import styled from "styled-components"

export default function PopupArticleHelp({ isOpen, onClose }) {
  const helpText = [
    "For an image that fills the width:",
    '<img class="img" src="http://i3.ytimg.com/vi/JtYc2oW_Zsk/hqdefault.jpg" />',
  ]

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Help in Styling Articles</DialogTitle>
      <DialogContent>
        <p>For an image that fills the width:</p>
        <StyledCodeBlock>
          {
            '<img class="img" src="http://i3.ytimg.com/vi/JtYc2oW_Zsk/hqdefault.jpg" />'
          }
        </StyledCodeBlock>
        <p>
          For videos use the following syntax. You need the link and a thumbnail
          image.
        </p>

        <StyledCodeBlock>
          {
            '<a title="View in YouTube" href="https://www.youtube.com/watch?v=JtYc2oW_Zsk"><img style="display: block; margin-left: auto; margin-right: auto;" src="http://i3.ytimg.com/vi/JtYc2oW_Zsk/hqdefault.jpg" /></a>'
          }
        </StyledCodeBlock>

        <p>For YouTube videos you can use this to get an image:</p>

        <StyledLink>
          <a href="http://www.get-youtube-thumbnail.com/" target="_blank">
            http://www.get-youtube-thumbnail.com/
          </a>
        </StyledLink>

        <p>For a button:</p>

        <StyledCodeBlock>
          {'<a class="btn" href="https://google.com">Find out more</a>'}
        </StyledCodeBlock>

        <p>or full width:</p>

        <StyledCodeBlock>
          {
            '<a class="btn full-width" href="https://google.com">Find out more</a>'
          }
        </StyledCodeBlock>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const StyledCodeBlock = styled.p`
  padding: 0.5rem;
  color: darkred;
  border: solid 1px silver;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
`

const StyledLink = styled.p`
  text-decoration: underline;
  padding-bottom: 0.5rem;
`
