import React, { useState } from "react"
import MaterialTable from "material-table"
import { useHistory } from "react-router-dom"
import { getMessagesMaterialTable } from "services/messagesService"
import BackButton from "components/common/BackButton"
import Button from "components/common/Button"
import { PageError } from "components/common/PageError"

export default function MessagesPage() {
  //STATE

  const [pageError, setPageError] = useState(null)

  //HOOKS

  const history = useHistory()

  //EVENTS

  const getQuery = async query => {
    var data = await getMessagesMaterialTable(query)
    if (data.indexOf && data.indexOf("<html>") > -1)
      window.location.href = "/Account/Login"
    if (data.responseType === "SUCCESS") return data
    setPageError(data.responseMessage)
  }

  //RENDER

  if (pageError !== null) return <PageError>{pageError}</PageError>

  return (
    <div className="mb-4">
      <div className="mb-3 text-right">
        <BackButton>Back</BackButton>
        <Button className="ml-2" to="/message/new">
          Send New Message
        </Button>
      </div>
      <MaterialTable
        title="Messages"
        options={{ pageSize: 10, filtering: true }}
        data={getQuery}
        columns={[
          { title: "Title", field: "title" },
          {
            title: "Sent",
            field: "sent",
          },
        ]}
        onRowClick={(event, rowData) => {
          history.push(`/message/${rowData.id}`)
        }}
      />
    </div>
  )
}
