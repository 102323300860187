import { useSnackbar } from "notistack"

/* This is for handling API response errors (e.g. performing actions on the page). It shows an error snackbar.
   To handle errors when the page loads use the PageError component. That shows an error panel.

  import useApiError from "hooks/useApiError"

  //Error Handling
  const { responseError, showError } = useApiError()

  .then(data => { responseError(data) }).catch(err => { showError() })

  or

  .then(data => { if (!responseError(data)) { do something after call } }).catch(err => { showError() })

  */

function useApiError() {
  const { enqueueSnackbar } = useSnackbar()

  function responseError(response) {
    //if (response.responseType === "EXCEPTION") {
    if (response.responseType !== "SUCCESS") {
      console.log(
        "Showing error because response type is",
        response.responseType
      )
      showError(response.responseMessage)
      return true
    }
    return false
  }
  function showError(message) {
    if (!message)
      message = "Unable to connect. If this continues please contact support"
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: 9000,
    })
  }

  return {
    responseError,
    showError,
  }
}

export default useApiError
